import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReferEarnCard from "../Components/DashBoard/ReferEarnCard/ReferEarnCard";
import "./Dashboard.css";
import TokenCard from "../Components/DashBoard/Tabs/TokenCard";
import PaymentCard from "../Components/DashBoard/Tabs/PaymentCard";
import {
  getCurrentChainId,
  getCurrentOwner,
} from "../ContractAction/PaymentContractAction";
import OuterBg from "../Images/BgPattern/Background_Dark.png";
import useWalletInfo from "../Hooks/WalletInfo/useWalletInfo";
import { useChainId } from "wagmi";

function Dashboard() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
    console.log(toggleState);
  };

  // state for API
  const [tokenData, setTokenData] = useState();
  // const [trueOwnerData, setTrueOwnerData] = useState([]);
  // const [falseOwnerData, setFalseOwnerData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [paymentData, setPaymentData] = useState();
  const { address, isConnected, isDisconnected } = useWalletInfo();
  const chainId = useChainId();
  console.log("User ChainID", chainId);
  const previousChainId = useRef(chainId);

  // const WalletAddress = localStorage.getItem("walletAddress") || "";

  // const processOwnership = async (item) => {
  //   const [isCurrentOwer, currentOwner] = await getCurrentOwner(
  //     item?.contactaddress,
  //     item?.chainId
  //   );

  //   if (item?.tokenowneraddress !== currentOwner) {
  //     item?.tokenowneraddress = currentOwner;
  //   }

  //   return {
  //     item,
  //     isCurrentOwer,
  //   };
  // };

  const getTokenData = async () => {
    try {
      const response = await fetch(
        `https://trendifytokens.io/api/files/getalltokendata?walletaddress=${address}`
      );
      const jsonResponse = await response.json();
      console.log("JSON Response", jsonResponse);
      if (jsonResponse) {
        const sortedData = jsonResponse?.data?.sort((a, b) => b.id - a.id);
        setTokenData(sortedData);

        // for (const item of sortedData) {
        //   const { item: updatedItem, isCurrentOwer } = await processOwnership(item);

        //   if (isCurrentOwner) {
        //     setTrueOwnerData((oldList) => [...oldList, updatedItem]);
        //   } else {
        //     setFalseOwnerData((oldList) => [...oldList, updatedItem]);
        //   }
        // }
        setLoader(false);
      }
      console.log("Get API Token Data", jsonResponse);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("TRUE OWNER", trueOwnerData);
  // console.log("FALSE OWNER", falseOwnerData);

  const getPaymentDashboardData = async () => {
    try {
      const response = await fetch(
        `https://trendifytokens.io/api/files/dashoboardtokendata?walletaddress=${address}`
      );
      const jsonResponse = await response.json();
      console.log("Payement Data in dashboard", jsonResponse);
      if (jsonResponse) {
        const filteredData = jsonResponse?.data?.filter(
          (item) =>
            item?.walletaddress == address && item?.paymentchainid == chainId
        );

        const sortedData = filteredData?.sort((a, b) => b?.id - a?.id);
        setPaymentData(sortedData);
        console.log("Payment", sortedData);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTokenData();
    getPaymentDashboardData();
  }, [address]);

  useEffect(() => {
    if (chainId !== previousChainId.current) {
      previousChainId.current = chainId; // Update previousChainId
      window.location.reload(); // Refresh the page
    }
  }, [chainId]);

  const allTokensInvalid = tokenData?.every(
    (item) => item?.deploystatus === false || item?.contactaddress === null
  );

  const getChainId = async () => {
    try {
      const chainId = await getCurrentChainId();
      console.log("chainId in Dash", chainId);
    } catch (error) {
      console.log(error);
    }
  };
  getChainId();

  console.log("Token Data in dashboard", tokenData);
  return (
    <div
      style={{
        backgroundImage: `url(${OuterBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        padding: "1rem",
      }}
    >
      <section
        className="custom-container"
        style={{ width: "90%", margin: "auto" }}
      >
        <Row>
          <Col md={3}>{/* <ReferEarnCard /> */}</Col>
          {/* Tabs Component */}
          <Col md={12} className="p-0">
            <div className="rounded-3 px-2 py-3 mx-2 my-3 my-md-0 h-100 dashboard-primary-bg">
              {/* Tabs Button */}
              <div className="d-flex gap-2">
                <div
                  onClick={() => toggleTab(1)}
                  className={`mx-3 my-2`}
                  style={{
                    borderBottom: toggleState === 1 ? "2px solid #45d09a" : "",
                    color: toggleState === 1 ? "#45d09a" : "white",
                    cursor: "pointer",
                  }}
                >
                  Deployed Tokens
                </div>
                <div
                  onClick={() => toggleTab(2)}
                  className="mx-3 my-2"
                  style={{
                    borderBottom: toggleState === 2 ? "2px solid #45d09a" : "",
                    color: toggleState === 2 ? "#45d09a" : "white",
                    cursor: "pointer",
                  }}
                >
                  Downloaded Tokens
                </div>
              </div>

              {/* Tabs Content */}
              <div className="px-1">
                <div
                  style={{
                    display: toggleState === 1 ? "block" : "none",
                  }}
                >
                  {/* <p style={{ display: "block", color: "white" }}>
                  Current Owner
                </p> */}
                  <div className="d-flex align-items-center justify-content-center justify-content-md-start flex-wrap gap-3">
                    {loader && <div className="loader my-4  "></div>}
                    {/* {!loader && allTokensInvalid && tokenData?.length == 0 && (
                    <h1 className="text-white">Create Your First Token!</h1>
                  )} */}

                    {/* {trueOwnerData?.map((item) => {
                    return (
                      <>
                        <TokenCard data={item} />
                      </>
                    );
                  })} */}
                    {tokenData?.length == 0 && (
                      <h1 className="text-white">Create Your First Token!</h1>
                    )}
                    {tokenData?.map((item) => {
                      if (
                        item?.deploystatus === true &&
                        item?.walletaddress === address
                      ) {
                        return (
                          <>
                            <TokenCard data={item} />
                          </>
                        );
                      }
                    })}
                  </div>
                  {/* <div
                  style={{
                    width: "100%",
                    height: "1px",
                    margin: "10px 0",
                    background: "gray",
                  }}
                ></div>
                <p style={{ color: "white" }}>Other Owner</p>
                <div className="d-flex align-items-center justify-content-center justify-content-md-start flex-wrap gap-3">
                  {falseOwnerData?.map((item) => {
                    return (
                      <>
                        <TokenCard data={item} />
                      </>
                    );
                  })}
                </div> */}
                </div>
                <div
                  style={{
                    display: toggleState === 2 ? "block" : "none",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center justify-content-md-start flex-wrap gap-3">
                    {loader && <div className="loader my-4  "></div>}
                    {paymentData?.map((item) => {
                      return (
                        <>
                          <PaymentCard data={item} />
                        </>
                      );
                    })}
                    {paymentData?.length == 0 && (
                      <h1 className="text-white">Create Your First Token!</h1>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}
export default Dashboard;
