export const truncateText = (text) => {
  if (text?.length > 8) {
    return `${text.slice(0, 6)}...${text.slice(-4)}`;
  }
  return text;
};

export const formatInteger = (number) => {
  if (!number) return "-";

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

// export const resetAll = (shouldReload = true) => {
//   const localStorageData = JSON.parse(localStorage.getItem("formData"));

//   // Reset data for General
//   if (localStorageData) {
//     const resetDataGeneral = {
//       ...localStorageData,
//       tokenName: "",
//       tokenSymbol: "",
//       decimal: 18,
//       initialSupply: "",
//       mintable: false,
//       mintableInput: "",
//       supplyrecipitent: false,
//       supplyrecipitentInput: "",
//       tokenowner: false,
//       tokenOwnerInput: "",
//       defaultExchange: false,
//       defaultExchangeSelect: "",
//       customExchangeInput: "",
//       antibotCooldown: false,
//       antibotCooldownInput: "",
//       antibotCoolDownTime: "Seconds",
//       enbletradingFunction: false,
//       maxamountperWallet: false,
//       maxamountperWalletInput: "",
//       maxTransactionLimit: false,
//       maxTransactionLimitInput: "",
//       onBuyingCheck: false,
//       onBuyingCheckInput: "",
//       onSellingCheck: false,
//       onSellingCheckInput: "",
//       onTransferringCheck: false,
//       onTransferringCheckinput: "",
//       pausable: false,
//       blacklist: false,
//       foregintokenRecovery: false,
//       permit: false,
//     };
//     localStorage.setItem("formData", JSON.stringify(resetDataGeneral));
//   }

//   // Reset data for Default Exchange
//   // const resetDataDefaultExchange = {
//   //   ...localStorageData,
//   //   defaultExchange: false,
//   //   defaultExchangeSelect: "Elk Finance (Polygon Mumbai)",
//   //   customExchangeInput: "",
//   //   antibotCooldown: false,
//   //   antibotCooldownInput: "",
//   //   antibotCoolDownTime: "Seconds",
//   //   enbletradingFunction: false,
//   //   maxamountperWallet: false,
//   //   maxamountperWalletInput: "",
//   //   maxTransactionLimit: false,
//   //   maxTransactionLimitInput: "",
//   //   onBuyingCheck: false,
//   //   onBuyingCheckInput: "",
//   //   onSellingCheck: false,
//   //   onSellingCheckInput: "",
//   //   onTransferringCheck: false,
//   //   onTransferringCheckinput: "",
//   //   pausable: false,
//   //   blacklist: false,
//   //   foregintokenRecovery: false,
//   //   permit: false,
//   // };
//   // localStorage.setItem("formData", JSON.stringify(resetDataDefaultExchange));

//   // Reset data for Taxes
//   const localstorageInfo = JSON.parse(localStorage.getItem("formData"));

//   const initialFormData = {
//     defaultExchange: localstorageInfo?.defaultExchange,
//     defaultExchangeSelect: "",
//     customExchangeInput: "",
//     liquidityTax: false,
//     liquidityBuyRate: false,
//     liquiditySellRate: false,
//     liquidityTransferRate: false,
//     liquidityBuyInput: "",
//     liquiditySellInput: "",
//     liquidityTransferInput: "",
//     dividendTax: false,
//     dividendSelect: "MATIC",
//     dividendCustomTokenInput: "",
//     dividendBuyRate: false,
//     dividendSellRate: false,
//     dividendTransferRate: false,
//     dividendBuyInput: "",
//     dividendSellInput: "",
//     dividendTransferInput: "",
//     dividendEligibilityAmount: "",
//     autoClaimIntervalInput: "",
//     autoClaimIntervalTime: "Seconds",
//     gasAutoClaimInput: "",
//     swapRatioInput: "",
//     autoBurnTax: false,
//     autoBurnTaxInput: "",
//     autoBurnBuyRate: false,
//     autoBurnSellRate: false,
//     autoBurnTransferRate: false,
//     autoBurnBuyInput: "",
//     autoBurnSellInput: "",
//     autoBurnTransferInput: "",
//     devTax: false,
//   };
//   localStorage.setItem("taxesFormData", JSON.stringify(initialFormData));

//   const walletTaxesInfo = JSON.parse(localStorage.getItem("walletTaxes"));

//   if (walletTaxesInfo) {
//     const resetWalletTaxes = walletTaxesInfo.map((_, index) => ({
//       id: index + 1,
//       walletTaxname: "",
//       walletRecipientAddress: "",
//       walletBuyRate: false,
//       walletSellRate: false,
//       walletTransferRate: false,
//       walletBuyInput: "",
//       walletSellInput: "",
//       walletTransferRateInput: "",
//       walletTaxRateInput: "",
//     }));
//     localStorage.setItem("walletTaxes", JSON.stringify(resetWalletTaxes));
//   }

//   if (shouldReload) {
//     window.location.reload();
//   }
// };
export const resetAll = (shouldReload = true) => {
  if (shouldReload) {
    window.location.reload();
    localStorage.clear();
  }
};

export const convertCooldownTime = (seconds, cooldownTimeFormat) => {
  if (!cooldownTimeFormat || !seconds) {
    return `${seconds} seconds`; // Default to seconds if no format is found or input is invalid
  }

  let result;

  switch (cooldownTimeFormat.toLowerCase()) {
    case "seconds":
      result = `${seconds} Seconds`;
      break;
    case "minutes":
      result = `${seconds / 60} Minutes`;
      break;
    case "hours":
      result = `${seconds / 3600} Hours`;
      break;
    default:
      result = `${seconds} seconds`; // Fallback to seconds if the format is unrecognized
  }

  return result;
};

// export const validateTaxInput = (value) => {
//   // Validate input for decimal numbers between 0 and 25
//   const regex = /^\d{1,2}(\.\d{0,2})?$/;
//   const floatValue = parseFloat(value);
//   return (
//     regex.test(value) &&
//     !isNaN(floatValue) &&
//     floatValue >= 0 &&
//     floatValue <= 25
//   );
// };

export const formatWithSpaces = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
